import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminDashBoard = () => {
    const navigate = useNavigate();  // Cambia useHistory por useNavigate

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');  // Redirige al login si no está autenticado
        }

        const isAdmin = localStorage.getItem('isAdmin');
        if (isAdmin !== 'true') {
            navigate('/ventas');  // Redirige a ventas si no es admin
        }
    }, [navigate]);  // Cambia history por navigate en las dependencias

    return (
        <div>
            <h1>Panel de Administración</h1>
            {/* Aquí puedes agregar las funcionalidades de creación de usuarios y vendedores */}
        </div>
    );
};

export default AdminDashBoard;
