import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminDashboard from './pages/modulo-admin/AdminDashBoard';
import VentasDashboard from './pages/modulo-ventas/VentasDashboard';
import RegisterUser from './components/RegisterUser'; 
import LoginPage from './components/LoginPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login/" element={<LoginPage />} />
                <Route path="/administracion/" element={<AdminDashboard />} />
                <Route path="/ventas/*" element={<VentasDashboard />} />                
                <Route path="/usuarios/" element={<RegisterUser />} />                
            </Routes>
        </Router>
    );
}

export default App;
