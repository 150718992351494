import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Switch, Box, MenuItem, TextField, Typography, Modal, Fade, Backdrop } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Áreas predefinidas del consultorio
const RolUsuario = [
  { value: 'superuser', label: 'Administrador' },
  { value: 'asistente', label: 'Asistente' },
  { value: 'cliente', label: 'Cliente' },
  { value: 'Doctor', label: 'Doctor' },
  { value: 'Doctora', label: 'Doctora' },
];

const RegisterUser = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('add'); // Puede ser 'add' o 'edit'
  const [selectedUser, setSelectedUser] = useState({
    user_login: '',
    user_email: '',
    user_rol: '',
    user_password: ''
  });

  useEffect(() => {
    // Llamada para cargar usuarios
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('http://localhost:8000/users/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data;
        setUsers(data);
      } catch (error) {
        toast.error('Error al cargar usuarios.');
      }
    };

    fetchUsers();
  }, []);

  const handleToggleActive = async (userId, isActive) => {
    try {
      const token = localStorage.getItem('access_token');
      
      // Envía el cambio al backend
      const response = await axios.put(`http://localhost:8000/users/${userId}/active`, { 
        is_active: isActive },  // Envía el nuevo valor
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      // Si la actualización fue exitosa, actualiza el estado de los usuarios en el frontend
      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, is_active: isActive } : user
          )
        );
        toast.success('Estado del usuario actualizado exitosamente.');
      }
    } catch (error) {
      console.error('Error al actualizar el estado del usuario:', error);
      toast.error('Error al actualizar el estado del usuario.');
    }
  };
      
  const handleEdit = (user) => {
    // Establecer un estado para abrir el modal con la información del usuario
    setIsModalOpen(true);
    setModalType('edit'); // Establece el tipo de modal como 'edit'
    setSelectedUser(user); // Pasa la información del usuario seleccionado
  };

  const handleDelete = async (userId) => {
    try {
      if (window.confirm("¿Estás seguro de que deseas eliminar este usuario?")) {
        const token = localStorage.getItem('access_token');
        const response = await axios.delete(`http://localhost:8000/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setUsers(users.filter((user) => user.id !== userId));
          toast.success('Usuario eliminado exitosamente');
        }
      }
    } catch (error) {
      toast.error('Error al eliminar el usuario');
    }
  };

  const handleAddNew = () => {
    setIsModalOpen(true);
    setModalType('add'); // Establece el tipo de modal como 'add'
    setSelectedUser({
      user_login: '',
      user_email: '',
      user_rol: '',
      user_password: ''
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('access_token');

    try {
      if (modalType === 'add') {
        const response = await axios.post('http://localhost:8000/users/', selectedUser, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setUsers([...users, response.data]);
          toast.success('Usuario agregado exitosamente');
        }
      } else if (modalType === 'edit') {
        const response = await axios.put(`http://localhost:8000/users/${selectedUser.id}`, selectedUser, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          setUsers(users.map((user) => (user.id === selectedUser.id ? response.data : user)));
          toast.success('Usuario actualizado exitosamente');
        }
      }
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Error al guardar el usuario');
    }
  };

  return (
    <Box 
      component="form"       
      sx={{ 
        maxWidth: '600px',  
        margin: '0 auto',  
        padding: 2, 
        boxShadow: 3,  
        backgroundColor: '#ffffff',  
        borderRadius: 2,  
      }}
    >   
    <Box>
      <Box sx={{ marginBottom: '15px', fontWeight: 'bold' }}>
        <Typography variant="h6">Usuarios</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddNew}
        style={{ marginBottom: '20px', float: 'right' }}
      >
        Nuevo Usuario
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.user_login}</TableCell>
                <TableCell>{user.user_email}</TableCell>
                <TableCell>{user.user_rol}</TableCell>
                <TableCell>
                  <Switch
                    checked={Boolean(user.is_active)}  // Convertimos is_active a booleano
                    onChange={(e) => handleToggleActive(user.id, e.target.checked)}
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEdit(user)}>
                    <EditIcon style={{ color: "green" }} />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(user.id)}>
                    <DeleteIcon style={{ color: "#de2323" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer position="bottom-right" />

      {/* Modal para agregar/editar usuario */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1500,
        }}
      >
        <Fade in={isModalOpen}>
          <Box
            sx={{
              backgroundColor: 'white',
              padding: 3,
              margin: 'auto',
              width: 320,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)', // Centrar modal
              boxShadow: 24,
              borderRadius: 2,
          }}>
            <Typography variant="h6" component="h2">
              {modalType === 'add' ? 'Agregar Usuario' : 'Editar Usuario'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Usuario"
                value={selectedUser.user_login}
                size="small"
                onChange={(e) => setSelectedUser({ ...selectedUser, user_login: e.target.value })}
                margin="normal"
                sx={{ boxShadow: 3 }}
              />
              <TextField
                fullWidth
                label="Email"
                value={selectedUser.user_email}
                size="small"
                onChange={(e) => setSelectedUser({ ...selectedUser, user_email: e.target.value })}
                margin="normal"
                sx={{ boxShadow: 3 }}
              />
              <TextField
                fullWidth
                select
                label="Rol"
                value={selectedUser.user_rol}
                size="small"
                onChange={(e) => setSelectedUser({ ...selectedUser, user_rol: e.target.value })}
                margin="normal"
                sx={{ boxShadow: 3 }}
              >
                {RolUsuario.map((rol) => (
                  <MenuItem key={rol.value} value={rol.value}>
                    {rol.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField 
                fullWidth 
                label="Contraseña" 
                type="password"
                size="small" 
                value={selectedUser.user_password}
                onChange={(e) => setSelectedUser({ ...selectedUser, user_password: e.target.value })}  
                margin="normal"      
                sx={{ boxShadow: 3 }} 
              />                

              <Box sx={{ textAlign: 'right', mt: 2 }}>
                <Button fullWidth type="submit" variant="contained" color="primary">
                  {modalType === 'add' ? 'Agregar' : 'Guardar Cambios'}
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Box>
    </Box>
  );
};

export default RegisterUser;
