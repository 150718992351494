import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid, Typography, Box, TextField } from '@mui/material';

const NichosAtaud = ({ agregarAlPresupuesto }) => {
  const [planSeleccionado, setPlanSeleccionado] = useState(null);

  const planes = [
    { id: 1, item: 'Nicho de Ataud', servicio: 'Nicho de Ataud', precio: 1200 },
  ];

  const handlePlanChange = (e) => {
    const plan = planes.find((p) => p.id === e.target.value);
    setPlanSeleccionado(plan);
  };

  const handleAgregar = () => {
    if (planSeleccionado) {
      const item = {
        codigo: '',       
        tipo_servicio: planSeleccionado.item,        
        descripcion: planSeleccionado.servicio,
        modalidadPago: '',
        precioTotal: planSeleccionado.precio,
        pagoInicial: '',
        montoPorCuota: ''
        
      };

      agregarAlPresupuesto(item);           
    }
  };

  return (
    <>
      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        CONTRATO NICHOS DE ATAUDES
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Plan</InputLabel>
            <Select
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.servicio} - {plan.item} - ${plan.precio}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Monto a Pagar"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid> 
        <Grid item xs={12} sm={5}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregar}
            disabled={!planSeleccionado} // Deshabilitar si no hay plan seleccionado
          >
            Agregar al Contrato
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default NichosAtaud;
