import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid, Typography, Box, TextField } from '@mui/material';

const Inhumacion = ({ agregarAlPresupuesto }) => {
  const [planSeleccionado, setPlanSeleccionado] = useState(null);

  const planes = [
    { id: 1, item: 'INHUMACION DE CENIZAS', servicio: 'Servicio de Inhumación del Difunto, depositando las cenizas en un recipiente especialmente creado para ello en nuestra Piramide del Recuerdo. Identificación del nombre del difunto en plaquita de PVC instalada en la lapida principal. Servicio de tarjeta de invitación digital para familiares y amigos (Formato Preestablecidos)', precio: 200 },    
  ];

  const handlePlanChange = (e) => {
    const plan = planes.find((p) => p.id === e.target.value);
    setPlanSeleccionado(plan);
  };

  const handleAgregar = () => {
    if (planSeleccionado) {
      const item = {
        codigo: '',       
        tipo_servicio: planSeleccionado.item,        
        descripcion: planSeleccionado.servicio,
        modalidadPago: '',
        precioTotal: planSeleccionado.precio,
        pagoInicial: '',
        montoPorCuota: ''        
      };

      agregarAlPresupuesto(item);           
    }
  };

  return (
    <>
      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        CONTRATO INHUMACION DE CENIZAS
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Plan</InputLabel>
            <Select
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.item} - ${plan.precio}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>       
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Monto a Pagar"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid> 
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregar}
            disabled={!planSeleccionado} // Deshabilitar si no hay plan seleccionado
          >
            Añadir Contrato
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center" sx={{ marginTop: '3px' }} >
        <Grid item xs={12} sm={8}>
            <TextField
                fullWidth
                label="Detalles del Servicio"
                variant="outlined"
                size="small"
                value={planSeleccionado ? `${planSeleccionado.servicio}` : ''}
                InputProps={{ readOnly: true }}
                multiline
                rows={3}
                sx={{ boxShadow: 3 }}
            />
            </Grid>
        </Grid>      
    </>
  );
};

export default Inhumacion;
