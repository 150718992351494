import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Checkbox,
  TextField,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Cremacion from './Cremacion';
import Exequia from './Exequia';
import Inhumacion from './Inhumacion';
import Parcelas from './Parcelas';
import NichosColumbarios from './Columbarios';
import NichosAtaud from './NichosAtaud';

const VentasContrato = () => {
  const { producto } = useParams();  
  const [items, setItems] = useState([]); // Aquí se guardarán los productos añadidos 
  const [itemsSeleccionados, setItemsSeleccionados] = useState([]); // Para eliminar los ítems seleccionados
  const [totalContrato, setTotalContrato] = useState(0);
  const [modalidadPago, setModalidadPago] = useState(null);
  const [cliente, setCliente] = useState({
    nombre: '',
    cedula: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    nombre_documento: '',
    documento_identidad: null,       
  });

  // Actualiza los datos del cliente
  const handleClienteChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
  
    if (name === 'fecha_nacimiento') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad = edadCalculada.toString();
    }
  
    setCliente((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };  

  const agregarAlPresupuesto = (item) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  // Renderiza el formulario adecuado basado en el producto seleccionado
  const renderFormulario = () => {
    switch (producto) {
      case 'cremacion':
        return <Cremacion agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'honra-exequias':
        return <Exequia agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'inhumacion-cenizas':
        return <Inhumacion agregarAlPresupuesto={agregarAlPresupuesto} />;      
      case 'parcelas':
        return <Parcelas agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'nichos-ataud':
        return <NichosAtaud agregarAlPresupuesto={agregarAlPresupuesto} />;
        case 'nichos-columbarios':
          return <NichosColumbarios agregarAlPresupuesto={agregarAlPresupuesto} />;        
      default:
        return <div>Seleccione un producto o servicio</div>;
    }
  }; 

  // Funciones de eliminación separadas
  const handleEliminarItems = () => {
    const nuevosItems = items.filter((_, index) => !itemsSeleccionados.includes(index));
    setItems(nuevosItems);
    setItemsSeleccionados([]); // Reiniciar los ítems seleccionados
  };

  // Manejar la selección de los ítems para eliminar
  const handleSeleccionarItem = (index) => {
    const seleccionado = itemsSeleccionados.includes(index);
    if (seleccionado) {
      setItemsSeleccionados(itemsSeleccionados.filter((i) => i !== index));
    } else {
      setItemsSeleccionados([...itemsSeleccionados, index]);
    }
  };

  // Calcula la edad a partir de la fecha de nacimiento
  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const fecha_nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
    const mes = hoy.getMonth() - fecha_nacimiento.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
      edad--;
    }

    return edad;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCliente((prev) => ({
        ...prev,
        documento_identidad: file, // Cambiar para almacenar el archivo, no solo el nombre
        nombre_documento: file.name,
      }));
    }
  };

  useEffect(() => {
    const total = items.reduce((sum, item) => sum + (item.precioTotal || 0), 0);
  
    // Asegúrate de que haya al menos un item y toma la modalidad del primer elemento.
    const modalidadPago = items.length > 0 ? items[0].modalidadPago : null;
  
    setTotalContrato(total);
    setModalidadPago(modalidadPago); // Si tienes una función para guardar la modalidad
  }, [items]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");

    const formData = new FormData();

    // Agregar todos los campos del cliente al FormData
    Object.keys(cliente).forEach(key => {
      if (key === 'documento_identidad' && cliente[key] instanceof File) {
        formData.append(key, cliente[key], cliente[key].name);
      } else if (key === 'familiares') {
        formData.append(key, JSON.stringify(cliente[key]));
      } else {
        formData.append(key, cliente[key]);
      }
    });

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    try {

      const clienteResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/clientes/", formData, config);
      const clienteId = clienteResponse.data.id;

      //console.log("Cliente creado:", clienteResponse.data);

      const configContrato = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
      };      

      // Creación del contrato (mantén esta parte igual)
      const contratoData = {
        cliente_id: clienteId,
        vendedor_id: parseInt(localStorage.getItem("vendedor_id")),
        tipo_servicio: producto,  // Se usa `producto` como tipo de servicio
        modalidad_pago: modalidadPago,  // Ajusta este valor según la modalidad deseada o hazlo dinámico si es necesario
        precio_total: totalContrato, // Calcula el total sumando precios de `items`
        estado: "activo",
        fecha_vencimiento: new Date().toISOString(),  // Ajusta según la lógica de fechas
        fecha_actual: new Date().toISOString(),
        descripcion: `Contrato para ${producto}`,  // Descripción dinámica basada en el producto
        monto_adicional: 0,
        detalles: items.map(item => ({
          tipo_contrato: producto,
          descripcion: item.descripcion,    
          precio: item.precioTotal,
          modalidadPago: item.modalidadPago,
          pagoInicial: item.modalidadPago === "Financiado" ? parseFloat(item.pagoInicial) || 0 : null,
          cuotas: item.modalidadPago === "Financiado" ? parseInt(item.cuotas) || 0 : null,
          montoPorCuota: item.modalidadPago === "Financiado" ? parseFloat(item.montoPorCuota) || 0 : null,
          precioTotal: parseFloat(item.precioTotal) || 0,
        })),
      };      

      const contratoResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/contratos", contratoData, configContrato);      
      const contratoId = contratoResponse.data.id;
      
      toast.success("Cliente y contrato creados exitosamente");    

      setTimeout(() => {
        // Restablecer el estado del cliente
        setCliente({
            nombre: '',
            cedula: '',
            fecha_nacimiento: '',
            edad: '',
            telefono: '',
            celular: '',
            email: '',
            direccion: '',
            documento_identidad: null,
            familiares: [],         
        });

        // Limpia el resumen de los items
        setItems([]);
        setItemsSeleccionados([]);
    }, 3000); 

    } catch (error) {
      toast.error(`Error: ${error.response?.data?.detail || error.message}`);
    }
  };

  return (
    <Box component="form" 
      onSubmit={handleSubmit}       
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

      {renderFormulario()}      

      {/* DATOS DEL CLIENTE */}
      <Typography
        sx={{ marginTop: '10px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL CLIENTE
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Nombre cliente"
          variant="outlined"
          size="small"
          name="nombre"
          value={cliente.nombre}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Cédula o Rif"
          variant="outlined"
          size="small"
          name="cedula"
          value={cliente.cedula}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_nacimiento"
            value={cliente.fecha_nacimiento || ''}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Edad"
            variant="outlined"
            size="small"
            name="edad"
            value={cliente.edad}
            required
            sx={{ boxShadow: 3 }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Teléfono"
          variant="outlined"
          size="small"
          name="telefono"
          value={cliente.telefono}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Celular"
          variant="outlined"
          size="small"
          name="celular"
          value={cliente.celular}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          size="small"
          name="email"
          value={cliente.correo}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          label="Dirección"
          variant="outlined"
          size="small"
          name="direccion"
          value={cliente.direccion}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>            
        <input
          accept="image/*" // puedes restringir el tipo de archivo permitido
          style={{ display: 'none' }} // Ocultar el input real
          id="file-upload"
          type="file"                
          onChange={handleFileChange}
        />
        
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: '#0A4575', // Estilo personalizado
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#083a5e',
              },
            }}
          >
            Subir Documento de Identidad
          </Button>
        </label>
      </Grid>
      <Grid item xs={12} sm={2}>
          {/* Mostrar el nombre del archivo seleccionado */}
          {cliente.nombre_documento && (
            <Typography sx={{ marginTop: '10px', fontFamily: 'Abel' }}>
              <b>{cliente.nombre_documento}</b>
            </Typography>
          )}            
        </Grid>
    </Grid>
       
      <Typography sx={{ textAlign: 'center', marginTop: '25px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
        RESUMEN DEL CONTRATO
      </Typography>

      {/* Tabla de Items */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#0A4575', color: '#FFFFFF' }}>
            <TableRow>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Código</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Inicial</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Cuotas</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Monto Cuota</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Total</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Seleccionar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.codigo}</TableCell>
                <TableCell>{item.descripcion}</TableCell>
                <TableCell>{item.modalidadPago}</TableCell>
                <TableCell>{item.pagoInicial !== '' ? `$${item.pagoInicial}` : ''}</TableCell>
                <TableCell>{item.cuotas}</TableCell>
                <TableCell>{item.montoPorCuota !== '' ? `$${item.montoPorCuota}` : ''}</TableCell>
                <TableCell>{`$${item.precioTotal}`}</TableCell>
                <TableCell>
                  <Checkbox checked={itemsSeleccionados.includes(index)} onChange={() => handleSeleccionarItem(index)} sx={{ color: '#0A4575', '&.Mui-checked': { color: '#0A4575' } }} />
                </TableCell>
              </TableRow>
            ))}

            {/* Fila de Total */}
            <TableRow>
              <TableCell colSpan={6} align="right" >
                <Typography sx={{ color: '#0A4575', fontWeight: 'bold', fontSize: '16px' }}>Total:</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={{ fontWeight: 'bold', color: '#000000' }}>${totalContrato}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
        {/* Botón para eliminar ítems seleccionados */}
        <Button
          variant="outlined"
          color="error"
          sx={{ 
            backgroundColor: '#0A4575', // Color de fondo del botón
            color: '#FFFFFF !important', // Color del texto
            borderColor: '#0A4575', // Color del borde para mantener consistencia
            '&:hover': {
              backgroundColor: '#083a5e !important', // Color de fondo al pasar el cursor
            },
            marginRight: 2,
          }}
          onClick={handleEliminarItems}
          disabled={itemsSeleccionados.length === 0} // Deshabilitar si no hay ítems seleccionados
        >
          Eliminar Ítems
        </Button> 
      
        <Button
          type="submit"
          variant="contained"
          sx={{ 
            backgroundColor: '#0A4575', // Color de fondo
            color: '#FFFFFF', // Color del texto
            '&:hover': {
              backgroundColor: '#000000', // Color de fondo al pasar el cursor
            }
          }}
        >
          Guardar Contrato
        </Button>
      </Box>  
    </Box>                
      
  );
};

export default VentasContrato;
