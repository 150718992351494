import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';

const Columbarios = ({ agregarAlPresupuesto }) => {
  const [capilla, setCapilla] = useState('');
  const [lado, setLado] = useState('');
  const [cantidadNichos, setCantidadNichos] = useState('');
  const [fila, setFila] = useState('');
  const [columna, setColumna] = useState('');
  const [codigoNicho, setCodigoNicho] = useState(''); 
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [modalidadPago, setModalidadPago] = useState('');
  
  const planes = [
    { id: 1, item: 'Plan-01', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE EXTERNA- FILAS ALTAS)', precioFinanciado: 400, inicial: 80, cuotas: 8, precio_cuotas: 40, precioContado: 300 },
    { id: 2, item: 'Plan-02', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE EXTERNA- FILAS MEDIAS)', precioFinanciado: 500, inicial: 100, cuotas: 8, precio_cuotas: 50, precioContado: 400 },
    { id: 3, item: 'Plan-03', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE INTERNA- FILAS ALTAS)', precioFinanciado: 600, inicial: 120, cuotas: 8, precio_cuotas: 60, precioContado: 500 },
    { id: 4, item: 'Plan-04', servicio: 'COLUMBARIOS EN CAPILLA PRINCIPAL (PARTE INTERNA- FILAS MEDIAS)', precioFinanciado: 700, inicial: 140, cuotas: 8, precio_cuotas: 70, precioContado: 600 },
  ];

  const handlePlanChange = (e) => {
    const planId = parseInt(e.target.value, 10);
    const plan = planes.find((p) => p.id === planId);
    setPlanSeleccionado(plan);
  };

  const handleAgregar = () => {
    if (planSeleccionado) {
      const item = {
        codigo: codigoNicho,       
        descripcion: `${planSeleccionado.item} - ${planSeleccionado.servicio}`,      
        precioFinanciado: modalidadPago === 'financiado' ? planSeleccionado.precioFinanciado : '',
        servicio: planSeleccionado.servicio,
        pagoInicial: modalidadPago === 'financiado' ? planSeleccionado.inicial : '',
        modalidadPago: modalidadPago === 'financiado' ? 'Financiado' : 'De Contado',
        cuotas: modalidadPago === 'financiado' ? planSeleccionado.cuotas : '',
        montoPorCuota: modalidadPago === 'financiado' ? planSeleccionado.precio_cuotas : '',
        precioContado: modalidadPago === 'contado' ? planSeleccionado.precioContado : '',
        precioTotal: modalidadPago === 'contado' ? planSeleccionado.precioContado : planSeleccionado.precioFinanciado,
      };

      agregarAlPresupuesto(item);           
    }
  };

  // Generar el código del nicho cuando cambian las opciones de capilla, lado, etc.
  useEffect(() => {
    if (capilla && lado && cantidadNichos && fila && columna) {
      const codigo = `${capilla}-${lado}${cantidadNichos}-${fila}${columna}`;
      setCodigoNicho(codigo);
    }
  }, [capilla, lado, cantidadNichos, fila, columna]);

  return (
    <>
      <Typography sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }} variant="h5">
        CONTRATO NICHOS DE COLUMBARIOS
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {/* Capilla, Lado, Fila, etc. */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Capilla</InputLabel>
            <Select value={capilla} label="Capilla" onChange={(e) => setCapilla(e.target.value)}>
              <MenuItem value="CP1">Capilla Principal (CP1)</MenuItem>
              <MenuItem value="CC1">Capilla de Contemplación 1 (CC1)</MenuItem>
              <MenuItem value="CC2">Capilla de Contemplación 2 (CC2)</MenuItem>
              <MenuItem value="CC3">Capilla de Contemplación 3 (CC3)</MenuItem>
              <MenuItem value="CC4">Capilla de Contemplación 4 (CC4)</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Lado</InputLabel>
            <Select value={lado} label="Lado" onChange={(e) => setLado(e.target.value)}>
              {['A', 'B', 'C', 'D'].map((lado, index) => (
                <MenuItem key={index} value={lado}>
                  {lado}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Cantidad de Nichos</InputLabel>
            <Select value={cantidadNichos} label="Cantidad de Nichos" onChange={(e) => setCantidadNichos(e.target.value)}>
              <MenuItem value="1">Uno (1) Nicho</MenuItem>
              <MenuItem value="2">Dos (2) Nichos</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Fila</InputLabel>
            <Select value={fila} label="Fila" onChange={(e) => setFila(e.target.value)}>
              {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'].map((f, index) => (
                <MenuItem key={index} value={f}>
                  {f}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Columna</InputLabel>
            <Select value={columna} label="Columna" onChange={(e) => setColumna(e.target.value)}>
              {Array.from({ length: 51 }, (_, i) => i + 1).map((col, index) => (
                <MenuItem key={index} value={col}>
                  {col < 10 ? `0${col}` : col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      
      <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel id="planParcela-label">Plan</InputLabel>
            <Select
              labelId="planParcela-label"
              id="planParcela"
              label="Plan"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
              autoComplete="off"
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.servicio} - {plan.item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="modalidad">Modalidad de Pago</InputLabel>
            <Select
              labelId="modalidad de Pago"
              name="modalidad"
              value={modalidadPago}
              onChange={(e) => setModalidadPago(e.target.value)}
              label="Modalidad de Pago"
            >
              <MenuItem value="" disabled>
                Seleccione una opción
              </MenuItem>
              <MenuItem value="contado">De Contado</MenuItem>
              <MenuItem value="financiado">Financiado</MenuItem>
            </Select>
          </FormControl>
        </Grid>  

        <Grid item xs={12} sm={3}>
          {modalidadPago === 'contado' && planSeleccionado && (
            <TextField
              fullWidth
              label="Precio al Contado"
              variant="outlined"
              size="small"
              name="pago_contado"
              value={planSeleccionado.precioContado !== '' ? `$${planSeleccionado.precioContado}` : ''}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          )}
          {modalidadPago === 'financiado' && planSeleccionado && (
            <TextField
              fullWidth
              label="Precio Financiado"
              variant="outlined"
              size="small"
              name="pago_financiado"
              value={planSeleccionado.precioFinanciado !== '' ? `$${planSeleccionado.precioFinanciado}` : ''}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
      </Grid> 

      <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={3}>
          {modalidadPago === 'financiado' && (
            <TextField
              fullWidth
              label="Pago Inicial"
              variant="outlined"
              size="small"
              name="inicial"
              value={planSeleccionado.inicial !== '' ? `$${planSeleccionado.inicial}` : ''}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={3}>
          {modalidadPago === 'financiado' && (
            <TextField
              fullWidth
              label="Cuotas"
              variant="outlined"
              size="small"
              name="cuotas"
              value={planSeleccionado.cuotas}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={3}>
          {modalidadPago === 'financiado' && (
            <TextField
              fullWidth
              label="Monto por Cuota"
              variant="outlined"
              size="small"
              name="monto_cuotas"
              value={planSeleccionado.precio_cuotas !== '' ? `$${planSeleccionado.precio_cuotas}` : ''}
              sx={{ boxShadow: 3 }}
              InputProps={{ readOnly: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregar}
            disabled={!planSeleccionado}
          >
            Agregar al Contrato
          </Button>
        </Grid>       
      </Grid>
    </>      
  );
};

export default Columbarios;  
