import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Grid, Typography, Box, TextField, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Previsivos = () => {
  const [items, setItems] = useState([]); // Almacenar los ítems seleccionados
  const [itemsSeleccionados, setItemsSeleccionados] = useState([]); // Almacenar los índices de los ítems seleccionados
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [inicial, setInicial] = useState(0);
  const [montoFinanciar, setMontoFinanciar] = useState(0);
  const [cuotas, setCuotas] = useState(1);
  const [montoCuotas, setMontoCuotas] = useState(0);
  const [cliente, setCliente] = useState({
    nombre: '',
    cedula: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    nombre_documento: '',
    documento_identidad: null,
    cantidad_parientes: '',
    familiares: [],         
  });

  // Estado para la modalidad de pago y otros detalles
  const [modalidadPrevisivo, setModalidadPrevisivo] = useState({  
    descripcion: '',  
    fecha_actual: '',
    fecha_vencimiento: '',
    monto_adicional: 0,
    total: 0,
    tipo_servicio: 'previsivo',  // "Previsivo"
    tipo_contrato: '',
    modalidad: '',       // "Modalidad de Pago"
    inicial: 0,
    monto_financiar: 0,
    cuotas: 1,
    monto_cuotas: 0,    
  });

  // Actualiza los datos del cliente
  const handleClienteChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
  
    if (name === 'fecha_nacimiento') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad = edadCalculada.toString();
    }
  
    setCliente((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };  

  // Manejador específico para cambios en modalidadPrevisivo
  const handleModalidadPrevisivoChange = (e) => {
    const { name, value } = e.target;
    setModalidadPrevisivo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const planes = [
    { id: 1, item: 'Plan Básico', precio: 60 },
    { id: 2, item: 'Plan Standard', precio: 96 },
    { id: 3, item: 'Plan Premium', precio: 120 },
    { id: 4, item: 'Plan Platinum', precio: 180 },
  ];

  const handlePlanChange = (e) => {
    const planId = parseInt(e.target.value, 10);
    const plan = planes.find((p) => p.id === planId);
    setPlanSeleccionado(plan);
    
    // Actualizar la descripción en modalidadPrevisivo
    setModalidadPrevisivo((prev) => ({
      ...prev,
      descripcion: plan ? plan.item : '',
    }));
  }; 

  const handleSeleccionarItem = (index) => {
    if (itemsSeleccionados.includes(index)) {
      setItemsSeleccionados(itemsSeleccionados.filter((i) => i !== index));
    } else {
      setItemsSeleccionados([...itemsSeleccionados, index]);
    }
  };  

  const handleAgregar = () => {
    if (planSeleccionado) {
      const item = {
        codigo: '',
        descripcion: planSeleccionado.item,
        precio: planSeleccionado.precio,
        modalidadPago: modalidadPrevisivo.modalidad === 'financiado' ? 'Financiado' : 'De Contado',
        pagoInicial: modalidadPrevisivo.modalidad === 'financiado' ? inicial : '',
        cuotas: modalidadPrevisivo.modalidad === 'financiado' ? cuotas : '',
        montoPorCuota: modalidadPrevisivo.modalidad === 'financiado' ? montoCuotas : '',
        precioTotal: modalidadPrevisivo.total,
      };
  
      setItems([...items, item]);            
    }
  };

  // Funciones de eliminación separadas
  const handleEliminarItems = () => {
    const nuevosItems = items.filter((_, index) => !itemsSeleccionados.includes(index));
    setItems(nuevosItems);
    setItemsSeleccionados([]); // Reiniciar los ítems seleccionados
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCliente((prev) => ({
        ...prev,
        documento_identidad: file, // Cambiar para almacenar el archivo, no solo el nombre
        nombre_documento: file.name,
      }));
    }
  };

  // Calcula la edad a partir de la fecha de nacimiento
  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const fecha_nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
    const mes = hoy.getMonth() - fecha_nacimiento.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
      edad--;
    }

    return edad;
  };

  // Agrega un familiar
  const agregarFamiliar = () => {
    setCliente((prev) => ({
      ...prev,
      familiares: [
        ...prev.familiares,
        {
          nombre: '',
          cedula: '',
          fecha_nacimiento: '',
          edad: '',
          parentesco: '',
          precio: '',
          id_familiar: null,
        },
      ],
    }));
  };

  // Elimina un familiar
  const eliminarFamiliar = (index) => {
    const updatedFamiliares = cliente.familiares.filter((_, i) => i !== index);
    setCliente((prev) => ({
      ...prev,
      familiares: updatedFamiliares,
    }));
    calcularMontoAdicional(updatedFamiliares);
  };

  // Maneja el cambio en los familiares
  const handleFamiliarChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFamiliares = cliente.familiares.map((familiar, i) => {
      if (i === index) {
        const updatedFamiliar = { ...familiar, [name]: value };

        // Si el campo modificado es 'fecha_nacimiento', calcular la edad
        if (name === 'fecha_nacimiento') {
          const edadCalculada = calcularEdad(value);
          updatedFamiliar.edad = edadCalculada.toString();
        }

        // Si tenemos edad, parentesco y plan, hacemos la solicitud
        if (updatedFamiliar.edad && updatedFamiliar.parentesco && planSeleccionado) {
          const parentesco_id = parseInt(updatedFamiliar.parentesco, 10);
          const edad = parseInt(updatedFamiliar.edad, 10);
          const plan_id = parseInt(planSeleccionado.id, 10);

          // Llamada a la API
          axios
            .get('https://intranet.cementeriopuertasalcielo.com.ve:8000/api/previsivos/calcular_monto', {
              params: { plan: plan_id, parentesco: parentesco_id, edad },
            })
            .then((response) => {
              //console.log('Precio recibido:', response);
              updatedFamiliar.precio = parseFloat(response.data.monto);
          
              // Actualizamos el estado
              const nuevosFamiliares = [...cliente.familiares];
              nuevosFamiliares[i] = updatedFamiliar;
              setCliente((prev) => ({ ...prev, familiares: nuevosFamiliares }));
          
              // Recalculamos el monto adicional
              calcularMontoAdicional(nuevosFamiliares);
            })
            .catch((error) => {
              console.error(error);
            });
        }

        return updatedFamiliar;
      }
      return familiar;
    });

    setCliente((prev) => ({
      ...prev,
      familiares: updatedFamiliares,
    }));
  };

  // Función para calcular el monto adicional
  const calcularMontoAdicional = (familiares) => {
    let total = 0;
    familiares.forEach((familiar) => {
      if (familiar.precio) {
        total += parseFloat(familiar.precio);
      }
    });
    setModalidadPrevisivo((prev) => ({
      ...prev,
      monto_adicional: total,
    }));
  };

  // Función para obtener la fecha y hora actual
  const obtenerFechaFormateada = () => {
    const fecha = new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return fecha.toLocaleString('es-ES', options);
  };

  const obtenerFechaVencimiento = () => {
    const fecha = new Date();
    fecha.setFullYear(fecha.getFullYear() + 1);
    return fecha.toISOString().split('T')[0];  // Formato 'YYYY-MM-DD'
};

  useEffect(() => {
    setModalidadPrevisivo((prev) => ({
      ...prev,
      fecha_actual: obtenerFechaFormateada(),
      fecha_vencimiento: obtenerFechaVencimiento(),
    }));
  }, []);

  // Recalcular el total cada vez que cambie el plan o el monto adicional
  useEffect(() => {
    const planPrecio = planSeleccionado ? parseFloat(planSeleccionado.precio) : 0;
    setModalidadPrevisivo((prev) => ({
      ...prev,
      total: planPrecio + prev.monto_adicional,
    }));
  }, [planSeleccionado, modalidadPrevisivo.monto_adicional]);

  // Recalcular el monto a financiar cada vez que cambie el total o el inicial
  useEffect(() => {
    if (modalidadPrevisivo.modalidad === 'financiado') {
      const montoFinanciarCalculado = modalidadPrevisivo.total - inicial;
      setMontoFinanciar(montoFinanciarCalculado > 0 ? montoFinanciarCalculado : 0);
      setModalidadPrevisivo((prev) => ({
        ...prev,
        monto_financiar: montoFinanciarCalculado > 0 ? montoFinanciarCalculado : 0,
      }));
    } else {
      setMontoFinanciar(0);
      setModalidadPrevisivo((prev) => ({
        ...prev,
        monto_financiar: 0,
      }));
    }
  }, [modalidadPrevisivo.total, inicial, modalidadPrevisivo.modalidad]);

  // Recalcular el monto de las cuotas cada vez que cambie el monto a financiar o las cuotas
  useEffect(() => {
    if (modalidadPrevisivo.modalidad === 'financiado' && cuotas > 0) {
      const montoCuotaCalculado = montoFinanciar / cuotas;
      setMontoCuotas(montoCuotaCalculado > 0 ? montoCuotaCalculado : 0);
      setModalidadPrevisivo((prev) => ({
        ...prev,
        monto_cuotas: montoCuotaCalculado > 0 ? montoCuotaCalculado : 0,
      }));
    } else {
      setMontoCuotas(0);
      setModalidadPrevisivo((prev) => ({
        ...prev,
        monto_cuotas: 0,
      }));
    }
  }, [montoFinanciar, cuotas, modalidadPrevisivo.modalidad]);

  // Resetear valores si la modalidad cambia a no financiado
  useEffect(() => {
    if (modalidadPrevisivo.modalidad !== 'financiado') {
      setInicial(0);
      setMontoFinanciar(0);
      setCuotas(1);
      setMontoCuotas(0);
      setModalidadPrevisivo((prev) => ({
        ...prev,
        inicial: 0,
        monto_financiar: 0,
        cuotas: 1,
        monto_cuotas: 0,
      }));
    }
  }, [modalidadPrevisivo.modalidad]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");

    const formData = new FormData();

    // Agregar todos los campos del cliente al FormData
    Object.keys(cliente).forEach(key => {
      if (key === 'documento_identidad' && cliente[key] instanceof File) {
        formData.append(key, cliente[key], cliente[key].name);
      } else if (key === 'familiares') {
        formData.append(key, JSON.stringify(cliente[key]));
      } else {
        formData.append(key, cliente[key]);
      }
    });

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    try {

      const clienteResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/clientes/", formData, config);
      const clienteId = clienteResponse.data.id;

      //console.log("Cliente creado:", clienteResponse.data);

      const configContrato = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
      };      

      // Creación del contrato (mantén esta parte igual)
      const contratoData = {
        cliente_id: clienteId,
        vendedor_id: parseInt(localStorage.getItem("vendedor_id")),
        tipo_servicio: modalidadPrevisivo.tipo_servicio,
        modalidad_pago: modalidadPrevisivo.modalidad,
        precio_total: modalidadPrevisivo.total,
        estado: "activo",
        fecha_vencimiento: new Date(modalidadPrevisivo.fecha_vencimiento).toISOString(),
        fecha_actual: modalidadPrevisivo.fecha_actual,
        descripcion: modalidadPrevisivo.descripcion,
        monto_adicional: modalidadPrevisivo.monto_adicional || 0,
        detalles: items.map(item => ({
          tipo_contrato: modalidadPrevisivo.tipo_contrato,
          descripcion: item.descripcion,    
          precio: item.precio,
          modalidadPago: item.modalidadPago,
          pagoInicial: item.modalidadPago === "Financiado" ? parseFloat(item.pagoInicial) || 0 : null,
          cuotas: item.modalidadPago === "Financiado" ? parseInt(item.cuotas) || 0 : null,
          montoPorCuota: item.modalidadPago === "Financiado" ? parseFloat(item.montoPorCuota) || 0 : null,
          precioTotal: parseFloat(item.precioTotal) || 0,
        })),
      };

      const contratoResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/contratos", contratoData, configContrato);      
      console.log("Contrato creado:", contratoResponse.data);
      
      toast.success("Cliente y contrato creados exitosamente");
        
        setTimeout(() => {
          // Restablecer el estado del cliente
          setCliente({
              nombre: '',
              cedula: '',
              fecha_nacimiento: '',
              edad: '',
              telefono: '',
              celular: '',
              email: '',
              direccion: '',
              documento_identidad: null,
              familiares: [],         
          });

          // Restablecer el estado del contrato (modalidad de pago, resumen, etc.)
          setModalidadPrevisivo({
              tipo_servicio: '',
              modalidad: '',
              total: 0,
              inicial: 0,
              monto_financiar: 0,
              cuotas: 0,
              monto_cuotas: 0,
              fecha_vencimiento: '',
              fecha_actual: '',
              descripcion: '',
              monto_adicional: 0,
          });

          // Limpia el resumen de los items
          setItems([]);
      }, 3000);      

    } catch (error) {
      toast.error(`Error: ${error.response?.data?.detail || error.message}`);
    }
  };
    
  return (
    <Box component="form" 
      onSubmit={handleSubmit}       
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
      {/* DATOS DEL PLAN */}
      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        CONTRATO PREVISIVO
      </Typography>
      <Typography
        sx={{ marginTop: '15px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        PLAN A CONTRATAR
      </Typography>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Plan a Contratar</InputLabel>
            <Select
              label="Plan a Contratar"
              value={planSeleccionado ? planSeleccionado.id : ''}
              onChange={handlePlanChange}
            >
              <MenuItem value="" disabled>
                Selecciona un Plan
              </MenuItem>
              {planes.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.item} - {plan.precio}{'$'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Precio del Plan ($)"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha y Hora Actual"
            variant="outlined"
            size="small"
            value={modalidadPrevisivo.fecha_actual || ''}            
            InputProps={{
              readOnly: true,
            }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha y Hora Vencimiento"
            variant="outlined"
            size="small"
            value={modalidadPrevisivo.fecha_vencimiento}            
            InputProps={{
              readOnly: true,
            }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>                
      </Grid>

      {/* DATOS DEL CLIENTE */}
      <Typography
        sx={{ marginTop: '15px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL CLIENTE
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Nombre Solicitante"
            variant="outlined"
            size="small"
            name="nombre"
            value={cliente.solicitante}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Cédula o Rif"
            variant="outlined"
            size="small"
            name="cedula"
            value={cliente.cedula}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Fecha de Nacimiento"
              variant="outlined"
              type="date"
              size="small"
              name="fecha_nacimiento"
              value={cliente.fecha_nacimiento || ''}
              onChange={handleClienteChange}
              required
              sx={{ boxShadow: 3 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Edad"
              variant="outlined"
              size="small"
              name="edad"
              value={cliente.edad}
              required
              sx={{ boxShadow: 3 }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            size="small"
            name="telefono"
            value={cliente.telefono}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Celular"
            variant="outlined"
            size="small"
            name="celular"
            value={cliente.celular}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            size="small"
            name="email"
            value={cliente.email}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Dirección"
            variant="outlined"
            size="small"
            name="direccion"
            value={cliente.direccion}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>            
          <input
            accept="image/*" // Puedes restringir el tipo de archivo permitido
            style={{ display: 'none' }} // Ocultar el input real
            id="file-upload"
            type="file"
            onChange={handleFileChange}
          />
          
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              sx={{
                boxShadow: 3,
                backgroundColor: '#0A4575',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#000000',
                },
              }}
            >
              Subir Documento de Identidad
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} sm={2}>
          {/* Mostrar el nombre del archivo seleccionado */}
          {cliente.nombre_documento && (
            <Typography sx={{ marginTop: '10px', fontFamily: 'Abel' }}>
              <b>{cliente.nombre_documento}</b>
            </Typography>
          )}            
        </Grid>
      </Grid>

      {/* FAMILIARES */}
      <Typography
        sx={{ marginTop: '10px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        ASOCIAR PARIENTES
      </Typography>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="cantidad_parientes-label">¿Desea Asociar algún Pariente?</InputLabel>
            <Select
              labelId="cantidad_parientes-label"
              name="cantidad_parientes"
              value={cliente.cantidad_parientes}
              onChange={handleClienteChange}
              label="¿Desea Asociar algún Pariente?"
            >
              <MenuItem value="" disabled>
                Seleccione una opción
              </MenuItem>
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Sí</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {cliente.cantidad_parientes === '1' && (
          <Grid item xs={12} sm={6}>
            {/* Botones para agregar/eliminar familiares */}
            <Button
              sx={{ fontFamily: 'Abel, sans-serif', backgroundColor: '#0A4575', color: '#FFFFFF', marginRight: 2 }}
              variant="contained"
              onClick={agregarFamiliar}
            >
              Añadir Familiar
            </Button>
            <Button
              sx={{ fontFamily: 'Abel, sans-serif', backgroundColor: '#0A4575', color: '#FFFFFF' }}
              variant="contained"
              onClick={() => eliminarFamiliar(cliente.familiares.length - 1)}
            >
              Eliminar Familiar
            </Button>
          </Grid>
        )}
      </Grid>

      {cliente.cantidad_parientes === '1' && (
        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          {cliente.familiares.map((familiar, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Nombre"
                  variant="outlined"
                  size="small"
                  name="nombre"
                  value={familiar.nombre}
                  onChange={(e) => handleFamiliarChange(index, e)}
                  required
                  sx={{ boxShadow: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Cédula"
                  variant="outlined"
                  size="small"
                  name="cedula"
                  value={familiar.cedula}
                  onChange={(e) => handleFamiliarChange(index, e)}
                  required
                  sx={{ boxShadow: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Fecha de Nacimiento"
                  variant="outlined"
                  type="date"
                  size="small"
                  name="fecha_nacimiento"
                  value={familiar.fecha_nacimiento || ''}
                  onChange={(e) => handleFamiliarChange(index, e)}
                  required
                  sx={{ boxShadow: 3 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Edad"
                  variant="outlined"
                  size="small"
                  name="edad"
                  value={familiar.edad}
                  onChange={(e) => handleFamiliarChange(index, e)}
                  required
                  sx={{ boxShadow: 3 }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
                  <InputLabel id={`familiar_parentesco_${index}`}>Parentesco</InputLabel>
                  <Select
                    labelId={`familiar_parentesco_${index}`}
                    name="parentesco"
                    value={familiar.parentesco}
                    onChange={(e) => handleFamiliarChange(index, e)}
                    label="Parentesco"
                  >
                    <MenuItem value="" disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value="1">Padre/Madre</MenuItem>
                    <MenuItem value="2">Hijo(a)</MenuItem>
                    <MenuItem value="3">Esposo(a)/Conyugue</MenuItem>
                    <MenuItem value="4">Hermano(a)</MenuItem>
                    <MenuItem value="5">Abuelo(a)</MenuItem>
                    <MenuItem value="6">Tío(a)</MenuItem>
                    <MenuItem value="7">Primo(a)</MenuItem>
                    <MenuItem value="8">Otro(a)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}

      <Box>
        <Typography
          sx={{ marginTop: '20px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
          variant="h6"
        >
          MODALIDAD - LAPSOS DE CONTRATACIÓN
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Monto Adicional ($)"
            variant="outlined"
            name='monto_adicional'
            size="small"
            value={`$${modalidadPrevisivo.monto_adicional.toFixed(2)}`}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Total ($)"
            variant="outlined"
            name="total"
            size="small"
            value={`$${modalidadPrevisivo.total.toFixed(2)}`}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="tipo_contrato">Previsivo</InputLabel>
            <Select
              name="tipo_contrato"
              labelId="tipo_contrato"
              value={modalidadPrevisivo.tipo_contrato}            
              onChange={handleModalidadPrevisivoChange}
              label="tipo_contrato"
            >
              <MenuItem value="" disabled>
                Seleccione una opción
              </MenuItem>
              <MenuItem value="nuevo contrato">Nuevo Contrato</MenuItem>
              <MenuItem value="renovacion">Renovación de Contrato</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="modalidad">Modalidad de Pago</InputLabel>
            <Select
              labelId="modalidad de Pago"
              name="modalidad"
              value={modalidadPrevisivo.modalidad}
              onChange={handleModalidadPrevisivoChange}
              label="Modalidad de Pago"
            >
              <MenuItem value="" disabled>
                Seleccione una opción
              </MenuItem>
              <MenuItem value="contado">De Contado</MenuItem>
              <MenuItem value="financiado">Financiado</MenuItem>
            </Select>
          </FormControl>
        </Grid>   
    {modalidadPrevisivo.modalidad === 'financiado' && (
      <>             
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Inicial ($)"
            variant="outlined"
            size="small"
            name= 'inicial'
            type="number"
            value={inicial}
            onChange={(e) => setInicial(parseFloat(e.target.value) || 0)}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        {/* Monto a Financiar */}
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Monto a Financiar ($)"
            name='monto_financiar'
            variant="outlined"
            size="small"
            value={`$${montoFinanciar.toFixed(2)}`}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        {/* Cuotas */}
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Cuotas"
            variant="outlined"
            size="small"
            name= 'cuotas'
            type="number"
            value={cuotas}
            onChange={(e) => setCuotas(parseInt(e.target.value) || 1)}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        {/* Monto Cuotas */}
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Monto Cuotas ($)"
            name= 'monto_cuotas'
            variant="outlined"
            size="small"
            value={`$${montoCuotas.toFixed(2)}`}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>  
      </> 
    )}                                      
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregar}
            disabled={!planSeleccionado} // Deshabilitar si no hay plan seleccionado
          >
            Agregar al Contrato
          </Button>
        </Grid>
      </Grid>

      <Typography sx={{ textAlign: 'center', marginTop: '25px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
          RESUMEN DEL CONTRATO
        </Typography>

        {/* Tabla de Presupuesto */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#0A4575', color: '#FFFFFF' }}>
              <TableRow>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Código</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Inicial</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Cuotas</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Monto Cuota</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Total</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Seleccionar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.codigo}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{item.modalidadPago}</TableCell>
                  <TableCell>{item.pagoInicial !== '' ? `$${item.pagoInicial}` : ''}</TableCell>
                  <TableCell>{item.cuotas}</TableCell>
                  <TableCell>{item.montoPorCuota !== '' ? `$${item.montoPorCuota}` : ''}</TableCell>
                  <TableCell>{`$${item.precioTotal}`}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={itemsSeleccionados.includes(index)}
                      onChange={() => handleSeleccionarItem(index)}
                      sx={{
                        color: '#0A4575',
                        '&.Mui-checked': {
                          color: '#0A4575',
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>  
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          {/* Botón para eliminar ítems seleccionados */}
          <Button
            variant="outlined"
            color="error"
            sx={{ 
              boxShadow: 3,
              backgroundColor: '#0A4575', // Color de fondo del botón
              color: '#FFFFFF !important', // Color del texto
              borderColor: '#0A4575', // Color del borde para mantener consistencia
              '&:hover': {
                backgroundColor: '#000000 !important', // Color de fondo al pasar el cursor
              },
              marginRight: 2,
            }}
            onClick={handleEliminarItems}
            disabled={setItemsSeleccionados.length === 0} // Deshabilitar si no hay ítems seleccionados
          >
            Eliminar Ítems
          </Button> 

            <Button
              type="submit"
              variant="contained"
              sx={{ 
                boxShadow: 3,
                backgroundColor: '#0A4575', // Color de fondo
                color: '#FFFFFF', // Color del texto
                '&:hover': {
                  backgroundColor: '#000000', // Color de fondo al pasar el cursor
                }
              }}
            >
              Guardar Contrato
            </Button>
          </Box>
    </Box>
  );
};

export default Previsivos;
