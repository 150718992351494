import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServicioFunerario = () => {
  //const [items, setItems] = useState([]); 
  const [funerariosItems, setFunerariosItems] = useState([]);
  const [funerariosItemsSeleccionados, setFunerariosItemsSeleccionados] = useState([]);
  const [tipoSala, setTipoSala] = useState('');
  const [planSeleccionado, setPlanSeleccionado] = useState('');
  const [cliente, setCliente] = useState({
    nombre: '',
    cedula: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    nombre_documento: '',
    documento_identidad: null,       
  });

  // Actualiza los datos del cliente
  const handleClienteChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
  
    if (name === 'fecha_nacimiento') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad = edadCalculada.toString();
    }
  
    setCliente((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  }; 

  // Calcula la edad a partir de la fecha de nacimiento
  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const fecha_nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
    const mes = hoy.getMonth() - fecha_nacimiento.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
      edad--;
    }

    return edad;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCliente((prev) => ({
        ...prev,
        documento_identidad: file, // Cambiar para almacenar el archivo, no solo el nombre
        nombre_documento: file.name,
      }));
    }
  };

  const serviciosFunerarios = {
    sencilla: [
      { id: 1, tipo: 'Sala Sencilla', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '4 HORAS', precio_v: 40.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 80.00, ataud: 'ALQUILER DE ATAUD', precio_a: 60.00, precio: 230.00 },
      { id: 2, tipo: 'Sala Sencilla', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '6 HORAS', precio_v: 80.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 120.00, ataud: 'ALQUILER DE ATAUD', precio_a: 100.00, precio: 350.00 },
      { id: 3, tipo: 'Sala Sencilla', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '8 HORAS', precio_v: 100.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 250.00, ataud: 'ALQUILER DE ATAUD', precio_a: 150.00, precio: 550.00 },
    ],
    media: [
      { id: 1, tipo: 'Sala Media', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '4 HORAS', precio_v: 80.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 80.00, ataud: 'ALQUILER DE ATAUD', precio_a: 60.00, precio: 270.00 },
      { id: 2, tipo: 'Sala Media', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '6 HORAS', precio_v: 100.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 120.00, ataud: 'ALQUILER DE ATAUD', precio_a: 100.00, precio: 370.00 },
      { id: 3, tipo: 'Sala Media', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '8 HORAS', precio_v: 120.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 250.00, ataud: 'ALQUILER DE ATAUD', precio_a: 150.00, precio: 570.00 },

    ],
    vip: [
      { id: 1, tipo: 'Sala Vip', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '4 HORAS', precio_v: 100.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 80.00, ataud: 'ALQUILER DE ATAUD', precio_a: 60.00, precio: 290.00 },
      { id: 2, tipo: 'Sala Vip', traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '6 HORAS', precio_v: 120.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 120.00, ataud: 'ALQUILER DE ATAUD', precio_a: 100.00, precio: 390.00 },
      { id: 3, tipo: 'Sala Vip',  traslado: 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA', precio_traslado: 50.00, velacion: '8 HORAS', precio_v: 150.00, preparacion: 'PREPARACION DEL DIFUNTO', precio_d: 250.00, ataud: 'ALQUILER DE ATAUD', precio_a: 150.00, precio: 600.00 },

    ],
  };

  const handleTipoSalaChange = (e) => {
    setTipoSala(e.target.value);
    setPlanSeleccionado('');
  };

  const handlePlanChange = (e) => {
    const plan = serviciosFunerarios[tipoSala].find((p) => p.id === e.target.value);
    setPlanSeleccionado(plan);
  };

  const agregarContrato = () => {
    if (planSeleccionado) {
      const item = {
        descripcion: planSeleccionado.tipo,        
        traslado: planSeleccionado.precio_traslado,
        velacion: planSeleccionado.velacion,
        precioVelacion: planSeleccionado.precio_v,
        preparacion: planSeleccionado.precio_d,
        alquiler: planSeleccionado.precio_a,
        precio: planSeleccionado.precio,
      };
      setFunerariosItems((prevItems) => [...prevItems, item]); 
      setPlanSeleccionado(''); // Reiniciar el plan seleccionado después de añadirlo
    }
  };

  const handleSeleccionarFunerariosItem = (index) => {
    const seleccionado = funerariosItemsSeleccionados.includes(index);
    if (seleccionado) {
      setFunerariosItemsSeleccionados(funerariosItemsSeleccionados.filter((i) => i !== index));
    } else {
      setFunerariosItemsSeleccionados([...funerariosItemsSeleccionados, index]);
    }
  };  

  const handleEliminarFunerariosItems = () => {
    const nuevosFunerariosItems = funerariosItems.filter((_, index) => !funerariosItemsSeleccionados.includes(index));
    setFunerariosItems(nuevosFunerariosItems);
    setFunerariosItemsSeleccionados([]); // Reiniciar los ítems seleccionados de funerarios
  };

  const precioTotalContrato = funerariosItems.reduce(
    (total, item) => total + (item.traslado || 0) + (item.precioVelacion || 0) + (item.preparacion || 0) + (item.alquiler || 0),
    0
  ); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");

    const formData = new FormData();

    // Agregar todos los campos del cliente al FormData
    Object.keys(cliente).forEach(key => {
      if (key === 'documento_identidad' && cliente[key] instanceof File) {
        formData.append(key, cliente[key], cliente[key].name);
      } else {
        formData.append(key, cliente[key]);
      }
    });

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    try {
        const clienteResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/clientes/", formData, config);
        const clienteId = clienteResponse.data.id;

        const configContrato = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        const contratoData = {
          fecha_actual: new Date().toISOString(),
          fecha_vencimiento: null, 
          cliente_id: clienteId,
          vendedor_id: parseInt(localStorage.getItem("vendedor_id")),
          tipo_servicio: 'Servicio Funerario',
          //descripcion: 'Servicio Funerario',
          modalidad_pago: "contado",
          precio_total: precioTotalContrato,
          estado: "activo",
      
          // Mapeo corregido de funerariosItems a detalles
          detalles: funerariosItems.map(item => ({
              tipo_contrato: 'Servicio Funerario',
              descripcion: item.descripcion,
              precio: item.precio || 0,
              traslado: item.traslado,
              tiempo_velacion: item.velacion,
              precio_velacion: item.precioVelacion,  // Convertir a string
              incluye_preparacion: item.preparacion,  // Convertir a booleano
              incluye_ataud: item.alquiler,  // Convertir a booleano
              precioTotal: item.precio
          })),
      };     

        // Enviar contratoData al backend
        const contratoResponse = await axios.post("https://intranet.cementeriopuertasalcielo.com.ve:8000/api/contratos", contratoData, configContrato);      
        console.log("Contrato creado:", contratoResponse.data);
      
        toast.success("Cliente y contrato creados exitosamente");

        // Limpiar los campos y resetear el formulario
        setTimeout(() => {
            setCliente({
                nombre: '',
                cedula: '',
                fecha_nacimiento: '',
                edad: '',
                telefono: '',
                celular: '',
                email: '',
                direccion: '',
                documento_identidad: null,
            });

            setFunerariosItems([]);
            setFunerariosItemsSeleccionados([]);
        }, 3000); 

    } catch (error) {
        toast.error(`Error: ${error.response?.data?.detail || error.message}`);
    }
};


  return (
    <Box component="form" 
      onSubmit={handleSubmit}       
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />      

      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        CONTRATO SERVICIO FUNERARIO
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A CONTRATAR
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Tipo de Capilla</InputLabel>
            <Select value={tipoSala} label="Tipo de Capilla" onChange={handleTipoSalaChange}>
              <MenuItem value="" disabled>Selecciona el tipo de Capilla</MenuItem>
              <MenuItem value="sencilla">Sala Sencilla</MenuItem>
              <MenuItem value="media">Sala Media</MenuItem>
              <MenuItem value="vip">Sala VIP</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
              fullWidth  
              label= 'Traslado'            
              variant="outlined"
              size="small"
              value= 'VALENCIA NORTE - SAN DIEGO - NAGUANAGUA'
              InputProps={{ readOnly: true }}
              sx={{ boxShadow: 3 }}
            />          
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
              fullWidth  
              label= 'Costo'            
              variant="outlined"
              size="small"
              value={`$${50}`}           
              InputProps={{ readOnly: true }}
              sx={{ boxShadow: 3 }}
            />          
        </Grid>                 
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }} disabled={!tipoSala}>
            <InputLabel>Tiempo de Velación</InputLabel>
            <Select value={planSeleccionado ? planSeleccionado.id : ''} label="Tiempo de Velación" onChange={handlePlanChange}>
              <MenuItem value="" disabled>Selecciona un Plan</MenuItem>
              {tipoSala && serviciosFunerarios[tipoSala].map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>{plan.velacion}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Precio Velación"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio_v}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Preparaión del Difunto"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio_d}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Alquiler del Ataud"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio_a}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Precio Total"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${planSeleccionado.precio}` : ''}
            InputProps={{ readOnly: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={agregarContrato}
            disabled={!planSeleccionado}
          >
            Añadir Contrato
          </Button>
        </Grid>            
      </Grid> 

      {/* DATOS DEL CLIENTE */}
      <Typography
        sx={{ marginTop: '10px', marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL CLIENTE
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Nombre cliente"
          variant="outlined"
          size="small"
          name="nombre"
          value={cliente.nombre}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Cédula o Rif"
          variant="outlined"
          size="small"
          name="cedula"
          value={cliente.cedula}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_nacimiento"
            value={cliente.fecha_nacimiento || ''}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Edad"
            variant="outlined"
            size="small"
            name="edad"
            value={cliente.edad}
            required
            sx={{ boxShadow: 3 }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Teléfono"
          variant="outlined"
          size="small"
          name="telefono"
          value={cliente.telefono}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          label="Celular"
          variant="outlined"
          size="small"
          name="celular"
          value={cliente.celular}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          size="small"
          name="email"
          value={cliente.correo}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          label="Dirección"
          variant="outlined"
          size="small"
          name="direccion"
          value={cliente.direccion}
          onChange={handleClienteChange}
          required
          sx={{ boxShadow: 3 }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>            
        <input
          accept="image/*" // puedes restringir el tipo de archivo permitido
          style={{ display: 'none' }} // Ocultar el input real
          id="file-upload"
          type="file"                
          onChange={handleFileChange}
        />
        
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: '#0A4575', // Estilo personalizado
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#083a5e',
              },
            }}
          >
            Subir Documento de Identidad
          </Button>
        </label>
      </Grid>
      <Grid item xs={12} sm={2}>
          {/* Mostrar el nombre del archivo seleccionado */}
          {cliente.nombre_documento && (
            <Typography sx={{ marginTop: '10px', fontFamily: 'Abel' }}>
              <b>{cliente.nombre_documento}</b>
            </Typography>
          )}            
        </Grid>
    </Grid>

        <Typography sx={{ textAlign: 'center', marginTop: '25px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
          RESUMEN DE SERVICIOS FUNERARIOS
        </Typography>

        {/* Nueva Tabla de Servicios Funerarios */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#0A4575', color: '#FFFFFF' }}>
              <TableRow>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Capilla</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Traslado</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Horas Velación</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Velación</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Preparación Difunto</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Alquiler Ataud</TableCell>
                <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Seleccionar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {funerariosItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{`$${item.traslado}`}</TableCell>
                  <TableCell>{item.velacion}</TableCell>
                  <TableCell>{`$${item.precioVelacion}`}</TableCell>
                  <TableCell>{`$${item.preparacion}`}</TableCell>
                  <TableCell>{`$${item.alquiler}`}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={funerariosItemsSeleccionados.includes(index)}
                      onChange={() => handleSeleccionarFunerariosItem(index)}
                      sx={{
                        color: '#0A4575',
                        '&.Mui-checked': {
                          color: '#0A4575',
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}

              {/* Fila de Total */}
              <TableRow>
                <TableCell colSpan={5} >
                  
                </TableCell>
                <TableCell  align="right">
                <Typography 
                  component="div" 
                  sx={{ display: 'flex', justifyContent: 'space-between', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', fontSize: '18px' }}
                >
                  Total:
                  <Typography component="span" sx={{ fontWeight: 'bold', color: '#000000' }}>
                    ${funerariosItems.reduce((total, item) => total + (item.precio || 0), 0).toFixed(2)}
                  </Typography>
                </Typography>

                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          {/* Botón para eliminar ítems seleccionados */}
          <Button
            variant="outlined"
            color="error"
            sx={{ 
              backgroundColor: '#0A4575', // Color de fondo del botón
              color: '#FFFFFF !important', // Color del texto
              borderColor: '#0A4575', // Color del borde para mantener consistencia
              '&:hover': {
                backgroundColor: '#083a5e !important', // Color de fondo al pasar el cursor
              },
              marginRight: 2,
            }}
            onClick={handleEliminarFunerariosItems}
            disabled={funerariosItemsSeleccionados.length === 0} // Deshabilitar si no hay ítems seleccionados
          >
            Eliminar Ítems
          </Button>                              
          <Button
          type="submit"
          variant="contained"
          sx={{ 
            backgroundColor: '#0A4575', // Color de fondo
            color: '#FFFFFF', // Color del texto
            '&:hover': {
              backgroundColor: '#000000', // Color de fondo al pasar el cursor
            }
          }}
        >
          Guardar Contrato
        </Button>
      </Box>     
    </Box>
  );
};

export default ServicioFunerario;
